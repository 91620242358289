@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

@mixin basegrid {
    column-gap: 32px;
    display: grid;
    padding-left: $space-150;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    row-gap: $font-size-50;
    white-space: nowrap;
}

.tablehover {
    background: white;
    box-shadow: 0 $spacing-xxs $spacing-sm $colour-neutral-4;
    display: flex;
    flex-direction: column;
    font-variant-numeric: tabular-nums;
    max-width: 350px;
    min-width: 350px;
    padding: $space-50;
    position: absolute;
    visibility: hidden;

    &__grid {
        &__gap {
            @include basegrid;

            grid-template-columns: 1fr 1fr 1fr;
        }

        &__contrib {
            @include basegrid;

            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        &__emphasis {
            font-size: $font-size-75;
            font-weight: $font-weight-bold;
            text-align: start;
        }

        &__header {
            color: $colour-text-and-icon-3;
            font-size: $font-size-25;
            text-align: end;
        }

        &__property {
            font-size: $font-size-75;
        }

        &__percentage {
            color: $colour-text-and-icon-3;
            font-size: $font-size-50;
            text-align: right;
        }

        &__value {
            font-size: $font-size-75;
            text-align: end;

            &__warning {
                color: red;
                font-size: $font-size-75;
                text-align: end;
            }
        }
    }

    &__week {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &__name {
            font-size: $font-size-100;
            font-weight: $font-weight-bold;
        }

        &__range {
            color: $colour-text-and-icon-3;
            font-size: $font-size-50;
            font-weight: $font-weight-regular;
        }
    }

    &__details {
        &__row {
            display: flex;
            font-size: $font-size-75;
            font-weight: $font-weight-bold;
            justify-content: space-between;
            padding: $space-50 0 $space-50 0;
        }

        &__item-name {
            text-align: start;
            white-space: nowrap;
        }
    }

    &__divider {
        background-color: $colour-neutral-3;
        height: 1px;
        margin: $space-75 0 $space-75 0;
    }
}

.tablehover-show-left-to-right {
    @extend .tablehover;

    visibility: visible;
}

.tablehover-show-right-to-left {
    @extend .tablehover;

    margin-left: -304px;
    visibility: visible;
}
