@use "styles/_spacing.scss" as *;

.filter-bar {
    @media print {
        display: none;
    }
    
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: $spacing-s;
    justify-content: space-between;

    > div {
        display: flex;
        flex-direction: row;
        gap: $spacing-xs;
    }

    .manage-scenario {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        gap: $spacing-s;

        button {
            padding-bottom: $spacing-xxs;
        }
    }

    .show-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
    }
}
