@use "styles/_zindex.scss" as *;

.loading-indicator {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &__fixed {
        left: calc(50% + 254px/2); // sidebar width is 254px
        position: fixed;
        top: 50%;
        z-index: $zindexPrimary;
    }

    &__loading {
        &--active {
            opacity: 1;
            transition: opacity 0.25s ease-in;
        }

        &--inactive {
            opacity: 0;
            transition: opacity 0.25s ease-out;
        }
    }
}
