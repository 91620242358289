@use "styles/_spacing.scss" as *;

.action-plan-filter-bar {
    @media print {
        display: none;
    }
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__pill-container {
        display: flex;
        gap: $spacing-lg;
        justify-content: space-between;
    }

    &__search {
        display: flex;
        flex-direction: row;
        height: $spacing-xl;
        min-width: 320px;
    }
}
