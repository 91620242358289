@use "@ingka/variables/style.scss" as *;
@use "styles/spacing.scss" as *;
@use "styles/font.scss" as *;

.upload {
    &-success-validation {
        color: $colour-semantic-positive;
        display: flex;
    }

    &-failed-validation {
        align-items: center;
        color: $colour-semantic-negative;
        display: flex;
        flex-direction: row;
    }

    &-process-validation {
        color: $colour-neutral-5;
    }
}

.unit-name {
    display: flex;
    padding: 0 0 $spacing-sm 0;
}

.header {
    &-title {
        @include heading-m;

        margin: 0;
        padding: $spacing-sm 0;
    }
}

.main-div {
    position: relative;
    top: -$spacing-lg;
}

.external-budget {
    &__buttons-container {
        display: flex;
        gap: $spacing-sm;
        justify-content: space-between;
        padding: $spacing-xs 0;
    }
}