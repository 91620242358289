@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/_scroll" as *;

.business-units-table,
.cost-centres-table,
.departments-table,
.divisions-table {
    &__container {
        min-height: 36rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: $spacing-xs 0;
    }

    &__table {
        @include scroll-bar;

        height: 34.5rem;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: $spacing-xxs;
    }
}
