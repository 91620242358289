@use "@ingka/variables/style.scss" as *;

@mixin scroll-bar {
    &::-webkit-scrollbar {
        background-color: $colour-neutral-2;
        height: 5px;
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $colour-neutral-5;
        border-radius: 6px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $colour-neutral-6;
    }
}
