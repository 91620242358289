@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.time-period-display {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-bottom: $spacing-xxs;
    position: relative;
}

.disabled {
    opacity: 0.1;
    pointer-events: none;
}

.icon-row {
    display: flex;
    flex-direction: row;
    min-width: 120px;
}

.dot {
    background-color: black;
    border-radius: $radius-s;
    display: block;
    height: 6px;
    margin-left: 10px;
    margin-right: 5px;
    width: 6px;
}

.week-range {
    display: flex;
    flex-direction: row;

    &-text {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        div {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }
}

.bold-text {
    padding: $spacing-xs;
}
