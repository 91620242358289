@import url("@ingka/avatar/style.scss");
@import url("@ingka/accordion/style.scss");
@import url("@ingka/base/style.scss");
@import url("@ingka/button/style.scss");
@import url("@ingka/tooltip/style.scss");
@import url("@ingka/carousel/style.scss");
@import url("@ingka/focus/style.scss");
@import url("@ingka/forms/style.scss");
@import url("@ingka/hyperlink/style.scss");
@import url("@ingka/loading/style.scss");
@import url("@ingka/modal/style.scss");
@import url("@ingka/pill/style.scss");
@import url("@ingka/svg-icon/style.scss");
@import url("@ingka/table/style.scss");
@import url("@ingka/tabs/style.scss");
@import url("@ingka/toast/style.scss");
@import url("@ingka/variables/style.scss");
@import url("@ingka/inline-message/style.scss");
@import url("@ingka/text/style.scss");
@import url("@ingka/search/style.scss");
@import url("@ingka/switch/style.scss");
@import url("@ingka/list-view/style.scss");
@import url("@ingka/list-box/style.scss");
@import url("@ingka/combobox/style.scss");
@import url("@ingka/toggle/style.scss");
@import url("skapa_overrides.scss");
@import url("@ingka/aspect-ratio-box/style.scss");
@import url("@ingka/base/dist/style.css");
@import url("@ingka/image/style.scss");
@import url("@ingka-group-digital/cwds-react-header/style.css");
@import url("@ingka-group-digital/cwds-react-shared-tabs/style.css");
@import url("@ingka-group-digital/cwds-react-user-profile/style.css");
@import url("@ingka-group-digital/cwds-react-app-switcher/style.css");
@import url("@ingka-group-digital/cwds-react-shared-modal/style.css");
@import url("@ingka-group-digital/cwds-react-shared-nav/style.css");

html {
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    body {
        animation: fade-in ease-in 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        // font-size: $font-size-75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    }
}

/* Fix typeface */
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
}
