@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.enable-bu {
    &__container {
        width: 21rem;
    }

    &__btns {
        border-top: 1px solid $colour-static-grey;
        display: flex;
        justify-content: space-between;
        padding: $spacing-xs 0;
    }

    &__cancel-btn,
    &__save-btn {
        width: $space-1000;
    }

    &__header {
        align-items: center;
        display: flex;
        padding: $spacing-s 0;
    }

    &__tooltip {
        cursor: pointer;
        padding: 0 $spacing-xs;
    }
}
