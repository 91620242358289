@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/colors" as *;

.contract-hours-row {
    &__title-cell {
        text-align: left;

        &--title {
            margin-left: 28px;
        }
    }
}

.details {
    border-left: none;
    border-right: none;
    display: flex;
    flex-direction: column;
    height: 35px;
    justify-content: flex-start;
    padding: 0 $spacing-xs;
    text-align: left;
}

.contract-start {
    @extend .details;

    text-align: right;
}

.inactive {
    &#{&} {
        color: $colour-interactive-disabled-1;
        pointer-events: none;
    }
}