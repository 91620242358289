@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.add-scenario {
    &__header {
        border-bottom: 1px solid #f2f2f2;
    }

    &__select-scenario-type {
        margin-top: $space-200;
    }

    &__input-scenario-name {
        margin-top: $space-200;
    }

    &__error {
        margin-top: $space-200;
    }
}
