@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;
@use "styles/_font.scss" as *;

.contract-mix {
    &__container {
        width: 100%;
    }

    &__card-container {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing-md;
        margin: 0 auto;
        padding: $spacing-m;
    }

    &__header {
        color: $colour-text-and-icon-1;
        margin-bottom: 0;
        margin-left: -2px; // Skapa <Text> component is missaligned by 2 px
        margin-top: $spacing-m;
    }

    &__description {
        color: $colour-text-and-icon-3;
    }

    &__card {
        background-color: $colour-neutral-2;
        border-radius: $spacing-xxs;
        color: $colour-interactive-primary-bg-pressed;
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        justify-content: space-between;
        max-width: 240px;
        min-width: 120px;
        padding: $spacing-md;
    }

    &__label {
        @include body-m;

        align-items: center;
        color: $colour-text-and-icon-1;
        display: flex;
        flex-direction: column;
        font-weight: 700;
    }
    
    &__values {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &__percentage {
        @include heading-l;
    }

    &__coworkers {
        @include body-m;

        color: $colour-text-and-icon-2;
    }

    &__no-data-container {
        align-content: center;
        color: $colour-static-black;
        display: block;
        font-size: $font-size-1000;
        text-align: center;
        width: 100%;
    }
}
