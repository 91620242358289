@use "@ingka/variables/style.scss" as *;
@use "styles/_zindex.scss" as *;

.tooltip {
  opacity: 0;
  position: absolute;
  text-align: center;
  transition: opacity ease-in 0.3s;
  visibility: hidden;
  z-index: $zindexPrimary;
  
  &__default {
    background-color: black;
    border-radius: $space-50;
    color: white;
    font-size: $font-size-75;
    max-width: 300px;
    min-width: 200px;
    padding: $space-75;
    white-space: wrap;
    word-wrap: break-word;
  }
  
  &__discreet {
    @include body-s;

    background-color: $colour-neutral-1;
    border: solid 1px $colour-interactive-subtle-border-hover;
    padding: $space-25;
  }

  &__show {
    opacity: 1;
    visibility: visible;

    &:hover {
      display: none;
    }
  }

  & > span {
    font-weight: $font-weight-regular;
  }
}
