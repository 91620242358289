@use "@ingka/variables/style.scss" as *;
@use "styles/colors" as *;
@use "styles/_spacing.scss" as *;
@use "./variables.scss" as *;
@use "styles/_font.scss" as *;
@use "styles/_zindex.scss" as *;

.week-row {
    background-color: $colour-neutral-1;
    border-top: 1px solid $colour-neutral-3;
    box-shadow: 0 1px $colour-neutral-3, 0 -1px $colour-neutral-3;
    font-size: $font-size-75;
    position: sticky;
    top: 0;
    z-index: $zindexSecondary;

    th {
        border: none;
        color: $colour-neutral-6;
        font-weight: $font-weight-regular;
        height: 62px;
        text-align: center;

        .container-week {
            margin-bottom: -20px;
        }

        &:first-child {
            background-color: $colour-neutral-1;
            left: 0;
            position: sticky;
            top: 0;
            width: 200px;
        }

        &:nth-last-child(1) {
            background-color: $colour-neutral-1;
            border-left: 1.25px solid $colour-neutral-3;
            position: sticky;
            right: 0;
            top: 0;
        }
    }

    &__cell {
        display: flex;
        flex-basis: 100%;
        height: 100%;
        justify-content: space-between;
        white-space: nowrap;

        &__sort-button {
            display: flex;
            flex-basis: 100%;
            text-align: start;

            & > div {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

            &:hover {
                background-color: $colour-neutral-2;
                cursor: pointer;
            }
        }
    }

    &__footer-cell {
        align-items: center;
        display: flex;
        text-align: end;

        > div {
            padding-right: $spacing-xs;
        }
    }

    &__highlighted-week {
        background-color: $color-light-blue;
        box-shadow: inset 0 0 2px 2px $colour-static-ikea-brand-blue;
    }

    &__month {
        font-weight: 700;
    }
}
