@use "styles/colors" as *;
@use "@ingka/variables/style.scss" as *;

.greyed-out-cell {
    background-color: $colour-neutral-2;
    color: $colour-text-and-icon-3;
    font-weight: $font-weight-bold;

    &--start {
        padding: 0 $space-100;
        text-align: right;
    }

    &--end {
        padding: 0 $space-100;
        text-align: start;
    }
}
