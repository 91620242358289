@use "styles/_spacing.scss" as *;

.tabs {
    //Accessing skapa class with role attribute 
    div[role="tablist"] {
        padding-left: $spacing-m;
        width: auto;
    }

    &__panel {
        padding: 0;
    }
}
