@use "@ingka/variables/style.scss" as *;

.time-filter-box {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}

.error-filter-date {
    margin-bottom: $font-size-75;
    width: 330px;
}

.filter-date {
    width: 330px;
}
