@use "styles/_spacing.scss" as *;

.scenario {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $spacing-s;
    justify-content: space-between;

    > div {
        display: flex;
        flex-grow: 1;
    }
}
