@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/app.scss" as *;
@use "styles/_scroll" as *;

.sidebar {
    @media print {
        display: none;
    }

    background-color: $colour-neutral-2;
    border-right: 1px solid $colour-neutral-3;
    flex-shrink: 0;
    height: 95vh;
    overflow: hidden;
}

.resize-handle {
    @media print {
        display: none;
    }
    
    border-left: 3px solid $colour-neutral-4;
    box-sizing: border-box;
    cursor: col-resize;
    flex: none;
    height: 100%;
    position: relative;
    -webkit-touch-callout: none;
    user-select: none;
    width: 5px;

    &:hover,
    &:active {
        border-color: $colour-static-ikea-brand-blue;
        border-style: double;
    }
}

.om-filters {
    @include scroll-bar;

    background-color: $colour-neutral-2;
    box-sizing: border-box;
    flex: 1 1 auto;
    height: 90%;
    justify-content: center;
    margin: 0;
    overflow-y: scroll;
    padding: 0 $spacing-xs 0 $spacing-md;
    transition: width $duration-quick-web $ease-easy;
    width: auto;

    ul {
        list-style-type: none;
    }

    &-closed {
        background-color: $colour-neutral-2;
        box-sizing: border-box;
        flex-shrink: 0;
        height: 90%;
        overflow: hidden;
        transition: width $duration-quick-web $ease-easy;
        width: 50px;
    }

    .om-filters-accordion {
        opacity: 1;
        position: relative;
        top: -1px;
        transition: opacity $duration-quick-web $ease-easy;
        visibility: visible;
    }
}

.om-filters-collapse-button {
    padding-left: $spacing-xs;
}

.om-filters-toggle-button {
    padding-bottom: $spacing-lg;
}

.button-right-align {
    float: right;
}

.checkbox-container {
    padding: $spacing-xxs;

    &--indented {
        padding-left: 2.5rem; // Matches the checkbox width + padding before label. Aligns indented box with S in Select all
    }

    &--select-all {
        padding-left: 0;
    }
}
