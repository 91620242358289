@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.datepicker {
    display: flex;
    flex-direction: column;
    padding: 14px 0;

    input {
        border: 1px solid $colour-neutral-5;
        border-radius: $radius-s;
        box-sizing: border-box;
        height: 40px;
        min-width: 200px;
    }

    label {
        @include body-l;
    }
}

.disabled {
    input {
        background-color: $colour-interactive-disabled-1;
    }
}

.invalid {
    input {
        border-color: $colour-semantic-negative;
    }

    input:focus {
        border-width: 2px;
    }

    > span {
        color: $colour-semantic-negative;

        .error-icon-span {
            background-color: $colour-semantic-negative;
            border-radius: 15px;
            color: white;
            display: flex;
        }

        .error-icon {
            background-color: $colour-semantic-negative;
            border-radius: 15px;
            color: white;
        }
    }
}

::-webkit-datetime-edit {
    font-family: "Noto IKEA", "Noto Sans", Roboto, "Open Sans", system-ui, sans-serif;
    font-size: small;
    font-weight: $font-weight-regular;
}

::-webkit-calendar-picker-indicator {
    height: 18px;
    padding: $spacing-xs;
    width: 18px;
}
