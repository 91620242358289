@use "styles/_zindex.scss" as *;

.loading-wrapper {
    position: relative;
    z-index: $zindexPrimary;

    &__loading {
        &--active {
            opacity: 0;
            transition: opacity 0.25s ease-in;
        }

        &--inactive {
            opacity: 1;
            transition: opacity 0.25s ease-out;
        }
    }
}
