@use "styles/_font.scss" as *;
@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.top-header {
    &-title {
        color: $colour-text-and-icon-1;
        margin-bottom: 0;
        margin-left: -2px; // Skapa <Text> component is missaligned by 2 px
        margin-top: $spacing-m;
    }

    &-description {
        color: $colour-text-and-icon-3;
    }    
}
