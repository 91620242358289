@use "@ingka/variables/style.scss" as *;
@use "styles/_colors" as *;

input {
    background-color: rgb(0 88 163 / 0%);
    border: 0;
    color: $colour-text-and-icon-3;
    font-size: $font-size-75;
    outline: 0;
}

.td-cell {
    border-left: none;
    border-right: none;
    color: $colour-text-and-icon-3;
    min-width: 60px;

    &-edited {
        background-color: $color-light-blue;
        min-width: 55px;
    }

    &-focused {
        box-shadow: inset 0 0 1px 1px $colour-interactive-emphasised-bg-default;
    }

    &__error {
        background-color: rgb(224 7 81 / 8%);
        color: $colour-semantic-negative;
        font-weight: $font-weight-bold;

        input {
            color: $colour-semantic-negative;
            font-weight: $font-weight-bold;
        }
    }

    &__toast {
        &--success {
            background-color: green;
            border: none;
        }

        &--error {
            background-color: red;
            border: none;
        }
    }

    &__show-tooltip {
        /* stylelint-disable declaration-no-important */
        position: relative !important;
        /* stylelint-enable declaration-no-important */
    }
}

.td-select-cell {
    > span {
        position: inherit;
    }
}

.td-select-cell:hover {
    @extend .td-cell-focused;
}

.input-cell {
    max-width: 56px;
    min-width: 40px;

    &-edited {
        color: $colour-interactive-emphasised-bg-default;
        font-weight: 700;
        max-width: 56px;
        min-width: 40px;
    }
}

.disabled {
    color: $colour-interactive-disabled-1;
    pointer-events: none;
}

.enabled {
    color: inherit;
    pointer-events: all;
}
