@use "@ingka/variables/style.scss" as *;
@use "styles/spacing.scss" as *;

.avatar-small {
    display: flex;
    justify-content: center;

    img {
        border-radius: 20px;
        height: 40px;
        width: 40px;
    }

    .empty-circle {
        align-items: center;
        background-color: $colour-neutral-2;
        border: $colour-neutral-5;
        border-radius: 20px;
        border-style: solid;
        border-width: 1px;
        display: flex;
        font-size: x-large;
        height: $spacing-lg;
        justify-content: center;
        line-height: 1.5;
        width: $spacing-lg;
    }
}

.avatar-medium {
    display: flex;
    justify-content: center;

    @extend .avatar-small;

    img {
        border-radius: 30px;
        height: 60px;
        width: 60px;
    }

    .empty-circle {
        border-radius: 30px;
        font-size: xxx-large;
        height: 60px;
        width: 60px;
    }
}

.avatar-large {
    display: flex;
    justify-content: center;

    @extend .avatar-small;

    img {
        border-radius: 50px;
        height: 100px;
        width: 100px;
    }

    .empty-circle {
        border-radius: 50px;
        font-size: xxx-large;
        height: 100px;
        width: 100px;
    }
}
