/* stylelint-disable declaration-no-important */
.config-nav-button {
    align-content: center;
    align-self: center;
    color: inherit;
    
    > button {
        // Skapa overrides this with JS if we don't use !important
        color: inherit !important;

        &:hover {
            /* stylelint-disable-next-line function-allowed-list */
            color: invert($color: whitesmoke) !important;
        }
    }
}
