@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.disable-bu {
    &__container {
        color: $colour-static-dark-grey;
        height: 5.5rem;
    }

    &__dropdown {
        max-width: 300px;
    }

    &__note {
        width: max-content;
    }

    &__header {
        align-items: center;
        display: flex;
        padding: $spacing-s 0;
    }

    &__tooltip {
        cursor: pointer;
        padding: 0 $spacing-xs;
    }
}
