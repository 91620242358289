@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.country {
    &__header {
        padding: $spacing-lg;
    }

    &__header-text {
        margin: $spacing-m 0;
    }

    &__dropdown {
        max-width: 300px;
        padding: $spacing-md 0;
    }
}
