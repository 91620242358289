/* stylelint-disable function-allowed-list */

.app-bar {
    &__container {
        width: 100%;
    }

    &__watermark {
        &--dev {
            background-image: url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40"><text x="0" y="25" fill="%23fff" fill-opacity="0.2" font-family="Noto IKEA, Helvetica, sans-serif" font-size="0.7rem">dev</text></svg>');
        }

        &--demo {
            background-image: url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40"><text x="0" y="25" fill="%23fff" fill-opacity="0.2" font-family="Noto IKEA, Helvetica, sans-serif" font-size="0.7rem">demo</text></svg>');
        }

        &--stage {
            background-image: url('data:image/svg+xml;utf8,<svg style="transform:rotate(45deg)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 40"><text x="0" y="25" fill="%23fff" fill-opacity="0.2" font-family="Noto IKEA, Helvetica, sans-serif" font-size="0.7rem">stage</text></svg>');
        }

    }

}
