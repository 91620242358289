@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.coworker-details {
    &__inline-message {
        padding: $spacing-sm 0 0 $spacing-xl;
        width: 60vw;
    }
}

.coworker-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: $spacing-sm $spacing-md;

    .button-column {
        display: flex;
        flex-direction: row;
    }

    .button-row {
        flex-direction: row;
    }

    .info-panel {
        display: flex;
        flex-direction: row;
        gap: $spacing-sm;
        padding: $spacing-sm $spacing-md;
        text-align: start;
        white-space: nowrap;
        width: 70vw;

        .info-item {
            display: flex;
            flex-direction: column;
            gap: $spacing-xs;
            margin: 0 $spacing-xs;
            width: 50vw;

            .title {
                font-weight: $font-weight-bold;
            }

            .value {
                color: $colour-interactive-primary-bg-default;
            }
        }
    }
}

.coworker-edit-buttons {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    height: 130px;
    padding: $spacing-sm;

    .button {
        width: 220px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.modal {
    display: flex;
    flex-grow: 1;
}

.modal-buttons {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;

    .button-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: $spacing-md;

        .button {
            padding: 0 $spacing-sm;
            width: 200px;
        }
    }
}

.coworker-edit-modal-container {
    display: flex;
    gap: $spacing-sm;

    .input-field {
        width: 49%;
    }

    .column-info {
        border-bottom: 1px solid $colour-neutral-3;
        border-right: 1px solid $colour-neutral-3;
        line-height: 35px;
        width: 20%;
    }

    .coworker-name {
        font-size: 24px;
        font-weight: 700;
        padding: $spacing-md 0;
    }

    .row-info {
        border-bottom: 1px solid $colour-neutral-3;
        line-height: 30px;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        width: 70%;
    }

    .heading {
        font-weight: 700;
        padding: $spacing-sm 0;
    }

    .main-heading {
        font-size: 24px;
        font-weight: 700;
    }

    .block {
        display: flex;
        flex-direction: row;
        gap: $spacing-sm;

        .item {
            flex: 1 1 auto;

            div > div > input {
                text-align: left;
            }
        }

        .button {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .cost-centre-row {
        justify-content: space-between;
        padding: $spacing-xs 0;
    }

    .cost-centre-buttons-row {
        display: flex;
        justify-content: space-between;
    }

    .cost-centre-box {
        border: 1px solid $colour-neutral-3;
        margin-bottom: $spacing-md;
    }

    .cost-centre-items {
        justify-content: space-between;
        padding: $spacing-sm $spacing-md 0 $spacing-md;

        > div {
            display: flex;
        }
    }

    &__policies {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;
    }
}

.scenario-details {
    color: $colour-static-ikea-brand-blue;
    font-weight: 700;
}

.coworker-details-container {
    display: flex;
    flex-direction: column;

    .profile-container-bg {
        display: flex;
        padding: 0 $spacing-s
    }

    .profile-picture {
        padding: $spacing-xs;
    }

    .deactivated-coworker {
        font-weight: 700;
        padding-top: $spacing-sm;
    }

    .bold {
        @include heading-s;
    }

    .role {
        padding: $spacing-xxs $spacing-xs;
    }

    .office-location {
        color: $colour-static-black;
    }

}

.delete-prompt-btn {
    display: flex;
    width: 23rem;
}
