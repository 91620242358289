.app {
    @media print {
        height: auto;
    };

    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;

    .main {
        display: flex;
        flex-grow: 1;

        .view {
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
        }
    }
}

.app-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .app-logo {
        animation: app-logo-spin infinite 20s linear;
    }
}

.app-header {
    align-items: center;
    background-color: #282c34;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh;
}

.app-link {
    color: #61dafb;
}

@keyframes app-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
