@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;
@use "styles/_font.scss" as *;
@use "styles/_scroll" as *;

$border-grey-line: 1px solid $colour-static-grey;

.config {
    &__header-container {
        margin: $spacing-md 0;
    }

    &__container {
        background-color: $colour-static-light-grey;
        max-height: 95vh;
        padding: 2rem;
    }

    &__card {
        background-color: $colour-static-white;
        border-radius: $spacing-xs;
        box-sizing: border-box;
        display: flex;
        height: 68vh;
    }

    &__country {
        border-right: $border-grey-line;
        box-sizing: border-box;
        max-width: 500px;
        padding: $spacing-xs;
    }

    &__general-setting {
        flex: auto;

        &-div {
            padding: $spacing-xxs $spacing-m;
        }

        &-item {
            border-bottom: $border-grey-line;
            padding-bottom: $spacing-xs;
        }
    }
}
