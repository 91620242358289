@use "@ingka/variables/style.scss" as *;

.hover-div {
    position: absolute;
    z-index: $layer-3;
}

.gap {
    font-size: $font-size-75;

    &__cell {
        align-items: center;
        display: flex;
        font-variant-numeric: tabular-nums;
        height: 62px;
        justify-content: center;
        padding: 0 $space-25;
    }
}
