@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/_scroll" as *;

.hiring-needs {
    &__sub-header {
        padding: $spacing-xxs 0;
    }

    &__table-container {
        height: 37.6rem;
    }

    &__table {
        @include scroll-bar;

        th {
            &:first-child {
                min-width: 14.5rem;
            }

            &:nth-last-child(1) {
                background-color: $colour-neutral-1;
                position: sticky;
                right: 0;
                top: 0;
            }
        }

        tr {
            td {
                align-content: center;

                &:first-child {
                    padding-left: $space-50;
                }

                &:nth-last-child(1) {
                    background-color: $colour-neutral-1;
                    position: sticky;
                    right: 0;
                    text-align: center;
                }
            }

            &:last-child {
                background-color: $colour-static-light-grey;

                td {
                    &:last-child {
                        background-color: $colour-static-light-grey;
                    }
                }
            }

        }
    }

    &__month {
        font-weight: 700;
    }

    &__week {
        padding-top: $spacing-m;
    }
}
