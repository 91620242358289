@use "styles/_scroll" as *;
@use "@ingka/variables/style.scss" as *;


/* override default scroll bar in add newcoworker modal window */
.sheets {
    &__content-wrapper {
        @include scroll-bar;
        
        display: flex;
        overflow-x: hidden;
        
        > div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    } 
}

.modal-footer {
    display: flex;
    gap: 24px;
}

td, th {
    border: none;
    vertical-align: middle;
}

body {
    color: $colour-text-and-icon-1;
}