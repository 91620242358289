@use "styles/_spacing.scss" as *;

.default-templates {
    &__header {
        padding: $spacing-s 0;
    }

    &__listbox-item {
        cursor: pointer;
    }
}
