@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;
@use "styles/_font.scss" as *;
@use "styles/_scroll" as *;
@use "styles/_zindex.scss" as *;

.pa-table {
    @include scroll-bar;

    background-color: $colour-neutral-1;
    border: none;
    border-collapse: collapse; //TODO: need to use border-collapse: separate and rebuild the border styling
    box-sizing: border-box;
    font-size: $font-size-75;
    position: relative;
    table-layout: fixed;
    text-align: center;

    tr {
        th {
            border-bottom: 1px solid $colour-neutral-3;
            border-top: 1px solid $colour-neutral-3;
            padding: 0;
            vertical-align: middle;
        }

        td {
            border-bottom: 1px solid $colour-neutral-3;
            border-top: 1px solid $colour-neutral-3;
            display: table-cell;
            height: 60px;
            text-overflow: ellipsis;
            vertical-align: middle;
            width: 60px;

            &.detail {
                background-color: $colour-neutral-2;
            }

            &.inforow {
                text-align: left;
            }
        }

        td:nth-child(1) {
            background-color: $colour-neutral-1;
            border-left: none;
            border-right: 1px solid $colour-neutral-3;
            display: table-cell;
            justify-content: space-between;
            left: 0;
            max-width: 368px;
            min-width: 368px;
            position: sticky;
        }

        td:nth-last-child(1) {
            background-color: $colour-neutral-1;
            border-left: 1px solid $colour-neutral-3;
            position: sticky;
            right: 0;

            > div {
                display: flex;
                flex-direction: row;
                gap: 4px;
                justify-content: space-between;

                /* stylelint-disable-next-line selector-max-type */
                > div {
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    justify-content: flex-end;
                    padding-right: $spacing-xxs;
                    width: 62px;
                }
            }
        }
    }

    input {
        height: 33px;
        text-align: center;

        &:read-write {
            cursor: pointer;
        }

        &:read-only {
            cursor: default;
            outline: none;
        }
    }

    &__empty-message {
        margin: $space-100 0 $space-100 0;
        width: 100%;
    }
}

.details-cell {
    background-color: grey;
    height: 20px;
    width: 50px;
}

.error {
    background-color: $colour-interactive-destructive-bg-default;
    outline-color: $colour-interactive-destructive-bg-default;
}

input::-webkit-inner-spin-button {
    display: none;
}
