@use "@ingka/variables/style.scss" as *;
@use "styles/colors" as *;
@use "styles/_spacing.scss" as *;
@use "./variables.scss" as *;
@use "styles/_font.scss" as *;
@use "styles/_zindex.scss" as *;

.gap-row {
    background-color: $colour-neutral-1;
    background-color: $colour-neutral-2;
    border-bottom: 1px solid $colour-neutral-3;
    border-top: 1px solid $colour-neutral-3;
    box-shadow: 0 1px $colour-neutral-3;
    height: 62px;
    position: sticky;
    top: 62px;
    width: 64px;
    z-index: $zindexSecondary;

    &__weekends-and-evenings {
        top: 125px;
    }

    th {
        font-size: $font-size-75;

        &:not(:first-child, :nth-last-child(1)) {
            border: none;
        }

        &:hover {
            background-color: $colour-neutral-1;
            cursor: grab;
        }

        &:first-child {
            background-color: $colour-neutral-2;
            border-right: 1px solid $colour-neutral-3;
            left: 0;
            padding-left: $space-150;
            position: sticky;
            top: 0;
            width: 200px;
        }

        &:nth-last-child(1) {
            background-color: $colour-neutral-2;
            border-left: 1px solid $colour-neutral-3;
            position: sticky;
            right: 0;
            top: 0;
        }
    }

    &__display-cell {
        border-left: none;
        text-align: left;
    }

    &__highlighted-week {
        background-color: $color-light-blue;
        box-shadow: inset 0 0 2px 2px $colour-static-ikea-brand-blue;
    }
}
