@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.copy-scenario {
    &__form {
        width: 100%;
    }

    &__title {
        align-items: baseline;
        display: flex;
    }

    &__header {
        border-bottom: 1px solid #f2f2f2;
    }

    &__input-scenario-name {
        margin-top: $space-200;
    }
}
