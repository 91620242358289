@use "styles/_spacing.scss" as *;

.manage-scenarios {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
}

.remove-main-scenario {
    display: flex;
    flex-direction: column;
    gap: $spacing-lg;

    &__create-link {
        margin-top: $spacing-m;
    }

    &__checkboxes-container {
        margin-top: 0;
    }

    &__checkboxes-header {
        margin-bottom: $spacing-sm;
    }

    &__checkbox {
        margin: $spacing-s 0;
    }
}