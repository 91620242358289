@use "styles/_scroll" as *;
@use "styles/_spacing.scss" as *;
@use "styles/_app.scss" as *;
@use "@ingka/variables/style.scss" as *;

.container {
    @include scroll-bar;

    @media print {
        height: auto;
    };

    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    padding: $space-200;
    row-gap: $spacing-m;
    width: 800px;

    &__full-width {
        padding: 0;
        width: 100%;
    }

    &__align-horizontal {
        flex-direction: row;
    }

    &__align-vertical {
        flex-direction: column;
    }
}
