@use "@ingka/variables/style.scss" as *;
@use "styles/spacing.scss" as *;
@use "styles/scroll.scss" as *;
@use "styles/_font.scss" as *;
@use "styles/_zindex.scss" as *;

.table {
    margin-right: $spacing-xxs;

    &__coworker {
        display: inline-block;
    }

    &__name-cell {
        align-items: center;
        display: flex;
    }

    &__loading-indicator {
        left: calc(50% + 120px); // width of the loading indicator is 120px
        position: fixed;
        top: calc(50% + 80px); // height of the loading indicator is 80px
        transform: translate(-50%, -50%);
        z-index: $zindexPrimary;
    }

    &__loading {
        opacity: 0.1;
        transition: opacity 0.75s;
    }
}

// action details table
.details {
    &-container {
        &-buttons {
            @media print {
                display: none;
            }
        };

        padding: $spacing-sm $spacing-xl $spacing-sm $spacing-xl;

        button:nth-child(2) {
            margin-left: $spacing-sm;
        }
    }

    &-table {
        @media print {
            flex-direction: column;
        };

        display: flex;
        justify-content: space-between;
        padding-top: $spacing-md;
    }

    &-date {
        vertical-align: top;
        white-space: nowrap;
    }

    &-changes {
        vertical-align: top;

        &--on-single-line{
            white-space: nowrap;
        }

        &-container {
            min-width: 150px;
            padding-bottom: $spacing-sm;

            &--info {
                align-items: center;
                display: flex;
                gap: $spacing-xs;
                white-space: nowrap;
            }
        }

        &__holiday {
            color: $colour-semantic-positive;
        }

        &__absence {
            color: $colour-commercial-message-bti-red;
        }

        &__cc-distribution {
            margin-top: $spacing-xs;
        }

        &__negative {
            color: $colour-semantic-negative;
        }

        &__positive {
            color: $colour-semantic-positive;
        }

        &__changed-by-cell {
            max-width: 200px;
        }
    }

    &-note {
        @media print {
            padding-left: unset;
        };

        display: flex;
        flex: 1 1 25%;
        flex-direction: column;
        margin-top: $spacing-xxs;
        padding-left: $spacing-xl;

        textarea {
            @media print {
                height: 100px;
                padding: $spacing-xs;
            };
        
            border: 1px solid $colour-neutral-5;
            border-radius: $spacing-xxs;
            box-sizing: border-box;
            font-family: "Noto IKEA", "Noto Sans", Roboto, "Open Sans", system-ui, sans-serif;
            font-size: $fontSize-sm;
            overflow: hidden;
            overflow-y: scroll;
            padding: $spacing-s $spacing-xs;
            width: 100%;
        }

        label {
            color: $colour-neutral-6;
            font-family: "Noto IKEA", "Noto Sans", Roboto, "Open Sans", system-ui, sans-serif;
            font-size: $fontSize-ssm;
            line-height: 1.5;
            margin-bottom: $spacing-xs;
        }
    }
}

@media print {
    /* stylelint-disable-next-line selector-class-pattern */
    table[id="capacity-action-table"] {break-before: always;}
    div[data-testid="action-plan-coworker-details"] {break-after: always;}
}
