@use "@ingka/variables/style.scss" as *;
@use "styles/_scroll" as *;
@use "styles/_spacing.scss" as *;

.filter-bar {
    align-items: flex-end;
    display: flex;
    gap: $spacing-s;
    justify-content: space-between;

    .manage-scenario {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        gap: $spacing-s;

        button {
            padding-bottom: $spacing-xxs;
        }
    }

    .search {
        width: 350px;
    }
}

.table {
    @include scroll-bar;

    display: flex;
    left: -1px;
    overflow: auto;
    position: relative;
}

.manage-capacity {
    &__container {
        max-height: 95vh;
    }
}
