@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/colors" as *;
@use "./variables.scss" as *;

.table-row {
    position: relative;

    &__employee {
        align-self: center;
        overflow: hidden;
        padding-left: $space-150;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__coworker-name-container {
        max-width: 225px;
    }

    &__icon {
        color: #2068ac;
        margin-left: $spacing-xs;
    }

    &__footer {
        height: 100%;

  
        &__cell {
            &#{&} { // doubles the parent selector specificity
                &#{&} { // doubles the parent selector specificity
                align-items: center;
                justify-content: center;
                }
            }
        }
    }

    &__error {
        background-color: rgb(224 7 81 / 8%);
        color: $colour-semantic-negative;
        font-weight: $font-weight-bold;

        input {
            color: $colour-semantic-negative;
            font-weight: $font-weight-bold;
        }
    }
}

.coworker-detail {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 17rem;

    &__button {
        padding: 0 $spacing-sm 0 $spacing-sm;

        &--warning {
            /* stylelint-disable declaration-no-important */
            color: $colour-semantic-caution !important;
            /* stylelint-enable declaration-no-important */
        }

        &--active {
            margin-left: -3px;
        }
    }

    &--warning {
        /* stylelint-disable declaration-no-important */
        color: $colour-semantic-caution !important;
        /* stylelint-enable declaration-no-important */
    }

    :hover {
        text-decoration: underline;
    }
}

.complex-row-cell {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;

    >div:nth-child(1) {
        display: flex;
        min-width: $info-column-min-width;
    }

    >div:nth-child(2) {
        align-items: center;
        display: flex;
        flex-direction: row;
        font-weight: $font-weight-regular;
        justify-content: flex-end;
        padding-right: 24px;
        width: $header-cell-min-width;
    }
}

.details {
    border-left: none;
    border-right: none;
    line-height: 1;
    max-width: 120px;
    padding: 0 $spacing-xs;
    text-align: left;
}

.contract-start {
    @extend .details;

    text-align: right;
}

.coworker-name-dummy {
    box-shadow: inset $spacing-xs 0 0 0 $colour-static-ikea-brand-blue;
}

.disabled {
    &#{&} {
        color: $colour-interactive-disabled-1;
        pointer-events: none;
    }
}

.enabled {
    &#{&} {
        color: inherit;
        pointer-events: all;
    }
}