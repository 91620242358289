@use "styles/_spacing.scss" as *;
@use "@ingka/variables/style.scss" as *;

.new-coworker-container {
    display: flex;
    flex-direction: column;

    .heading-row {
        font-size: 24px;
        font-weight: 700;
        padding: $spacing-sm;
    }

    .input-field {
        padding: $spacing-sm 0;
    }

    .select-dropdown {
        @extend .input-field;

        &-toggle {
            display: flex;
            justify-content: flex-end;
        }
    }

    .date-field {
        padding: $spacing-xs 0;
    }

}

.add-coworker-button{
    width: max-content;
}
