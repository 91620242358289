@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/_scroll" as *;
@use "styles/_font.scss" as *;

.filter-bar {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;

    > span {
        margin-right: $spacing-md;

        &:nth-last-child(1) {
            margin-bottom: $spacing-xxs;
            margin-right: 0;
        }
    }
}

.print-only {
    @media print {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;
        justify-content: flex-start;
        width: auto;
    }

    display: none;
}

.action-plan {
    &-heading {
        font-size: $fontSize-md;
        font-weight: $font-weight-bold;
    }

    &-summary {
        font-size: $fontSize-s;
        font-weight: $font-weight-regular;
    }

    &__real-filter-bar {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
    }

    &__container {
        @media print {
            max-height: fit-content;
            overflow-y: visible;
        };

        max-height: 95vh;
        overflow: hidden;
    }

    &__loading-indicator {
        padding: $space-550 0;
    }

    &__table-container {
        margin-top: $space-250;
    }
}

.table-wrapper {
    @include scroll-bar;

    @media print {
        overflow-y: visible;
    };

    overflow-y: auto;
    padding: $spacing-xxs;

    thead {
        tr {
            th {
                font-size: $fontSize-s;
            }
        }
    }
}
