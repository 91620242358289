@use "@ingka/variables/style.scss" as *;
@use "styles/spacing.scss" as *;
@use "styles/font.scss" as *;

input[type="file"] {
    display: none;
}

.column {
    padding: $spacing-xs 0;
}

.heading {
    @include heading-s;

    padding-bottom: $spacing-xxs;
}

.description {
    font-size: $fontSize-s;
}

.button-column {
    display: flex;
    gap: $spacing-xs;
    justify-content: space-between;
    padding: $spacing-s 0;
}

.upload-button {
    width: 100%;
}

.upload-failed {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .headings {
        font-weight: $font-weight-bold;
        padding: $spacing-s 0;
    }

}
