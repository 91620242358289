/* stylelint-disable selector-max-type */
@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;
@use "styles/_font.scss" as *;
@use "styles/_colors.scss" as *;
@use "styles/_scroll" as *;
@use "styles/_zindex.scss" as *;

.tooltip {
    background: #000000b3;
    border-radius: 3px;
    color: white;
    margin: 8px 0;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transition: all .1s ease;

    table {
        margin: 0;

        thead {
            tr {
                border-width: 0;

                th {
                    border-width: 0;
                    font-size: $fontSize-ssm;
                }
            }

        }

        tbody {
            tr {
                background-color: inherit;
                border-width: 0;

                td {
                    border-width: 0;
                    font-size: $fontSize-s;
                    padding: 0;

                    span {
                        border-radius: 10px;
                        border-width: 2px;
                        display: inline-block;
                        height: 10px;
                        margin-right: 10px;
                        width: 10px;
                    }
                }
            }

        }

    }
}

.warning-message {
    align-items: center;
    color: $colour-static-dark-grey;
    display: flex;
    padding: $spacing-xs $spacing-sm;

    &-icon {
        fill: $colour-static-dark-grey;
        padding-right: $spacing-xxs;
    }
}

.wrapper {
    padding: $spacing-md 0;
}

.loading-wrapper-status {
    z-index: $zindexPrimary + 1; // Puts the tooltip from capacity status graph above the contract mix cards
}

.chart {
    svg {
        color: aqua;
    }
}

.scroll-container {
    @include scroll-bar;
    
    max-width: 100%;
    overflow-x: auto;
    width: 100%;

    &-body {
        padding-bottom: $spacing-sm;
    }
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-m;

    &-title {
        color: $colour-text-and-icon-1;
        margin-bottom: 0;
        margin-left: -2px;
    }

    &-description {
        color: $colour-text-and-icon-3;
        line-height: 1.5;
    }
}

.legend-wrapper {
    align-self: end;
}

.status-header-container{
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    .capacity-status-toggle {
        height: min-content;
    }
}

.legend {
    display: flex;
    flex-wrap: wrap;

    &-item-container {
        min-width: 90px;
        padding: 0 $spacing-sm;
    }

    &-title-container {
        align-items: center;
        display: flex;
        white-space: nowrap;
    }

    &-dot {
        background-color: $colour-static-grey;
        border-radius: 50%;
        height: $spacing-s;
        margin-right: $spacing-xs;
        width: $spacing-s;

        &--target {
            background-color: $colour-static-ikea-brand-blue;
        }
    }

    &-title {
        font-size: $fontSize-sm;
        font-weight: $font-weight-bold;
        line-height: $line-height-1100-long;
    }

    &-number {
        font-size: $fontSize-md;
        font-weight: $font-weight-bold;
        line-height: $fontSize-md;
    }

    &-unit {
        color: $colour-text-and-icon-3;
        font-size: $spacing-s;
        line-height: $line-height-1100-long;
        margin-left: $spacing-xxs;
    }

    &-number-unit-container {
        margin-left: $spacing-m;
        margin-top: $spacing-xxs;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
